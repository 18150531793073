import { AdminUiConfig } from "@vendure/common/lib/shared-types";
import { environment } from "src/environments/environment";

let vendureUiConfig: AdminUiConfig | undefined;

export async function loadAppConfig(): Promise<void> {
  vendureUiConfig = await fetch(
    environment.production
      ? "./vendure-ui-config.json"
      : "./vendure-ui-config-dev.json"
  ).then((res) => res.json());
}

export function getAppConfig(): AdminUiConfig {
  if (!vendureUiConfig) {
    throw new Error(`vendure ui config not loaded`);
  }
  return vendureUiConfig;
}
